$include-fonts: false;

@import "../setup/settings";

// Page
.page {
    min-height: unset !important;

    @include breakpoint(small up) {
        padding-top: 0;
    }
}

// Main Hero
.home-main {
    &.homepage {
        position: absolute;
        top: rem-calc(124); // change via js on load and resize
        left: 0;
        right: 0;

        img {
            width: 100%;
        }

        @include breakpoint(medium up) {
            top: rem-calc(160); // change via js on load and resize
        }

        @include breakpoint(large up) {
            top: 0;
        }
    }
}

.main-banner-homepage {
    @include breakpoint(medium down) {
        .custom-margin-top {
            margin-top: rem-calc(68);
        }
    }
    @include breakpoint(small down) {
        .custom-margin-top {
            margin-top: rem-calc(62);
        }
    }
}

.main {
    &-container {
        &--tezenis {
            .main-banner-homepage {
                @include breakpoint(small down) {
                    .custom-margin-top {
                        margin-top: rem-calc(55);
                    }
                }
            }
        }
    }
}
.slick-slide {
    img{
      width:100%
    }
}

.consent-modal {
    position: fixed;
    .tingle-modal-box {
        position: fixed;
        bottom: 10px;
        z-index: 10;
    }
}
